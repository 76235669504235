<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="extensionForms"
      :sort-mapping="sortMapping"
      :title="'extensions'"
      :total="total"
      :fetch-path="'extensionForm/fetch'"
      :filter-path="'extensionForm/filter'"
      :show-actions-column="false"
    >
      <template #customfilters>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Nom</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `Nom: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Convocatòries</label>
          <v-select
            v-model="filters.calls"
            multiple
            label="title"
            :options="convos"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Convocatòries: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'calls')"
          />
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Estat</label>
          <v-select
            v-model="filters.status"
            label="name"
            :options="statuses"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Estat: ${$event ? $event.name : ''}`, value: $event }, 'status')"
          />
        </div>
      </template>
    </CompleteTable>
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Nom',
      sortMapping: {
        Nom: 'user.name',
        Convòcatoria: 'convo.code',
        Àrea: 'area_code',
        Extensió: 'extensionLabel',
        Descripció: 'reason',
        Estat: 'status.status_table',
      },
      defaultFields: [
        {
          name: 'Nom',
          checked: true,
          order: 1,
        },
        {
          name: 'Convòcatoria',
          checked: true,
          order: 2,
        },
        {
          name: 'Àrea',
          checked: true,
          order: 3,
        },
        {
          name: 'Extensió',
          checked: true,
          order: 4,
        },
        {
          name: 'Descripció',
          checked: true,
          order: 5,
        },
        {
          name: 'Estat',
          checked: true,
          order: 6,
        },
      ],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      extensionForms: 'extensionForm/items',
      total: 'extensionForm/itemsTotal',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      convos: 'convo/convos',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'university-extension-form')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'university-extension-form',
      })
    }
    await this.$store.dispatch('extensionForm/fetch')
    await this.$store.dispatch('status/filterStatus', 'Extension requests')

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
    this.loading = false
  },
  methods: {
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      if (field === 'calls') {
        value.value.map(e => {
          e.name = e.title
          return e
        })
      }
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
  },
}
</script>

<style>

</style>
