<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="users"
      :sort-mapping="sortMapping"
      :title="'preseleccionats/des'"
      :total="total"
      :fetch-path="'academyAwarded/fetchOffered'"
      :filter-path="'academyAwarded/filterOffered'"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <span
            :class="['dis-ib', field.acceptance_doc.status == 2 ? 'text-success' : 'text-danger']"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Go to justification section"
          ><a
            :class="['btn', 'btn-icon', 'rounded-circle', field.acceptance_doc.status == 2 ? 'btn-flat-success' : 'btn-flat-danger' ]"
            :href="$router.resolve({ name: 'university.awarded.accpetnace-document', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
            target="_blank"
          ><i data-feather="file" /></a></span>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Nom</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `Nom: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >DNI</label>
          <input
            v-model="filters.card_number"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `DNI: ${$event.target.value}`, value: `${$event.target.value}` }, 'card_number')"
          >
        </div>
        <!-- <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Universities</label>
          <v-select
            multiple
            label="name"
            :options="institutions"
            :get-option-key="option => option.name"
            @input="saveFilter($event, 'hosts')"
            @search="onSearch({ name: $event, typeName: 'University' }, 'institutions/filter')"
          />
        </div> -->
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Departament</label>
          <input
            v-model="filters.department"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `Departament: ${$event.target.value}`, value: `${$event.target.value}` }, 'department')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Àrees</label>
          <v-select
            v-model="filters.areas"
            multiple
            label="name"
            :options="areas"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Àrees: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areas')"
            @search="onSearch({ name: $event }, 'areas/filter')"
          />
        </div>
      </template>
    </CompleteTable>
    <div
      v-else
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Academia Call',
      sortMapping: {
        Convocatòria: 'convo.code',
        Nom: 'user.true_name',
        DNI: 'identify_document',
        Departament: 'department',
        Àrea: 'area_code',
        'Icrea validació': 'icreaValidate',
      },
      defaultFields: [
        {
          name: 'Convocatòria',
          checked: true,
          order: 1,
        },
        {
          name: 'Nom',
          checked: true,
          order: 2,
        },
        {
          name: 'DNI',
          checked: true,
          order: 3,
        },
        {
          name: 'Departament',
          checked: true,
          order: 4,
        },
        {
          name: 'Àrea',
          checked: true,
          order: 5,
        },
        {
          name: 'Icrea validació',
          checked: true,
          order: 6,
        },
      ],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'academyAwarded/itemsOffered',
      total: 'academyAwarded/itemsTotalOffered',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      convos: 'convo/convos',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'university-selected')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'university-selected',
      })
    }

    await this.$store.dispatch('academyAwarded/fetchOffered')

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
    this.loading = false
  },
  methods: {
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
  },
}
</script>
