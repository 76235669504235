<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="users"
      :sort-mapping="sortMapping"
      :title="'premiats/des'"
      :total="total"
      :fetch-path="'academyAwarded/fetchAccepted'"
      :filter-path="'academyAwarded/filterAccepted'"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item
                :href="$router.resolve({name: routeShowDocument, params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
              >
                <i data-feather="file" />
                Justificació
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Nom</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `Nom: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >DNI</label>
          <input
            v-model="filters.card_number"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `DNI: ${$event.target.value}`, value: `${$event.target.value}` }, 'card_number')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Departament</label>
          <input
            v-model="filters.department"
            type="text"
            class="form-control"
            name="name"
            @change="saveFilter({ customLabel: true, name: `Departament: ${$event.target.value}`, value: `${$event.target.value}` }, 'department')"
          >
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Àrees</label>
          <v-select
            v-model="filters.areas"
            multiple
            label="name"
            :options="areas"
            :get-option-key="option => option.name"
            @input="saveFilter({ customLabel: true, name: `Àrees: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areas')"
            @search="onSearch({ name: $event }, 'areas/filter')"
          />
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Convocatòries</label>
          <v-select
            v-model="filters.calls"
            multiple
            label="title"
            :options="convos"
            :get-option-key="option => option.id"
            @input="saveFilter({ customLabel: true, name: `Convocatòries: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'calls')"
          />
        </div>
      </template>
    </CompleteTable>
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortedField: 'Academia Call',
      sortMapping: {
        Convòcatoria: 'convo_code',
        Nom: 'user.true_name',
        DNI: 'identify_document',
        Departament: 'department',
        Àrea: 'area_code',
        Import: 'importFormat',
        'Memòria científica': 'justification_doc_table',
        'Memòria econòmica': 'economic_justification_cat',
      },
      defaultFields: [
        {
          name: 'Convòcatoria',
          checked: true,
          order: 1,
        },
        {
          name: 'Nom',
          checked: true,
          order: 2,
        },
        {
          name: 'DNI',
          checked: true,
          order: 3,
        },
        {
          name: 'Àrea',
          checked: true,
          order: 4,
        },
        {
          name: 'Departament',
          checked: true,
          order: 5,
        },
        {
          name: 'Memòria científica',
          checked: true,
          order: 6,
        },
        {
          name: 'Memòria econòmica',
          checked: true,
          order: 7,
        },
      ],
      loading: true,
      routeShowDocument: '',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'academyAwarded/itemsAccepted',
      total: 'academyAwarded/itemsTotalAccepted',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      convos: 'convo/convos',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'university-academy-awarded')
    await this.$store.dispatch('academyAwarded/fetchAccepted')
    if (!this.fields || this.fields.length === undefined) {
    // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'university-academy-awarded',
      })
    }

    if (this.loggedUser.roles.includes('university')) {
      this.routeShowDocument = 'university.awarded.show'
    } else {
      this.routeShowDocument = 'admin.calls.academy_awarded.show'
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
    this.loading = false
  },
  methods: {
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      if (field === 'calls') {
        value.value.map(e => {
          e.name = e.title
          return e
        })
      }

      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
  },
}
</script>

<style>

</style>
